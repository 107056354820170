import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './ImageViewer.scss';

const ImageViewer = ({ imageUrl, onClose }) => {
  return (
    <div className='image-viewer'>
      <div className='image-viewer-close' onClick={onClose}>
        <button>
          <FontAwesomeIcon icon={['fa', 'times']} />
        </button>
      </div>
      <div className='image-viewer-image'>
        <img src={imageUrl} alt='sms-media' />
      </div>
    </div>
  );
};

export default ImageViewer;
