import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import moment from 'moment';
import './ChatSection.scss';
import { importMessageToQDeck } from '../../../../../services/messageService';
import {
  setChatScroll,
  updateMessage,
  setDataMistakesModalStatus,
  setImportToQDeckModalStatus
} from '../../../../../actions/chatActions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Loader, Modal } from '../../../../Common';
import { updateSurveyResponse } from '../../../../../services/userService';
import DataMistakesSection from './DataMistakesSection.jsx';
import Lines from './Lines.jsx';
import _ from 'lodash';
import { faDesktop, faMobileAlt } from '@fortawesome/free-solid-svg-icons';
import ChatAttachment from './ChatAttachment.jsx';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import store from '../../../../../store/store';
import ChatSectionFooter from './ChatSectionFooter.jsx';
import ImageViewer from './ImageViewer.jsx';
import { chatSelector, userSelector } from '../../../../../selectors/mainSelectors.js';

const MessageMetadata = ({ data }) => {
  const messageIsUnhandled = (message) => _.isNil(_.get(message, 'id'));

  return (
    <>
      {messageIsUnhandled(data) && <span className='unhandledMessage'>Message did not get processed</span>}
      {(() => {
        switch (data.client_type) {
          case 'web':
            return <FontAwesomeIcon icon={faDesktop} title='web' />;
          case 'react native':
            return <FontAwesomeIcon icon={faMobileAlt} title='app' />;
          default:
            return null;
        }
      })()}
      {data.delivered && <FontAwesomeIcon icon={['fa', 'check-double']} />}
      {!data.delivered && <FontAwesomeIcon icon={['fa', 'check']} />}
      {data.bookkeeper && <span className='text'> {data.bookkeeper}</span>}
      <span className='text'>{moment(data.time).format('MM-DD-YYYY HH:mm:ss')}</span>
    </>
  );
};

const ChatSection = ({ isExternalAccountant }) => {
  const dispatch = useDispatch();
  const [showImageViewer, setShowImageViewer] = useState(false);
  const [largeMedialUrl, setLargeMedialUrl] = useState('');
  const [messagesEnd, setMessagesEnd] = useState(undefined);

  const user = useSelector(userSelector);
  const chat = useSelector(chatSelector);

  useEffect(() => {
    const scrollToBottom = (chatScroll) => {
      if (messagesEnd) {
        messagesEnd.scrollIntoView({
          behavior: chatScroll.style,
          block: 'end',
          inline: 'nearest'
        });
        dispatch(setChatScroll({ scroll: false, style: 'auto' }));
      }
    };
    if (chat.chatScroll.scroll) {
      scrollToBottom(chat.chatScroll);
    }
  }, [chat.chatScroll, messagesEnd, dispatch]);

  /**
   * @description Open Image Viewer to see large image
   * @param {String} mediaUrl
   */
  const openImageViewer = (mediaUrl) => {
    setShowImageViewer(true);
    setLargeMedialUrl(mediaUrl);
  };

  /**
   * @description Close Image Viewer
   */
  const closeImageViewer = () => {
    setShowImageViewer(false);
    setLargeMedialUrl('');
  };

  /**
   *
   * @description Creates an onClickHandler
   * @param {Object} message - the message object that the survey is attached to
   * @param {Number} response - the response number that the handler is attached to
   */
  const createHandleSurveyResponseClick = ({ message, response }) => async () => {
    if (message.surveyResponse === String(response)) {
      // Undoes the response
      dispatch(updateSurveyResponse({ surveyId: message.surveyId, surveyResponse: null }));
      dispatch(
        updateMessage(message.id, {
          surveyResponse: null
        })
      );
    } else {
      // Sets the response
      dispatch(updateSurveyResponse({ surveyId: message.surveyId, surveyResponse: response }));
      dispatch(
        updateMessage(message.id, {
          surveyResponse: String(response)
        })
      );
    }
  };

  const messageIsUnhandled = (message) => _.isNil(_.get(message, 'id'));

  const canReportDataMistake = (message) => {
    return messageIsUnhandled(message) || !['user', 'manual', undefined].includes(message.textType);
  };

  const showDataMistakesModal = (message) => {
    dispatch(setDataMistakesModalStatus({ open: true, message }));
  };

  const hideDataMistakesModal = () => {
    dispatch(setDataMistakesModalStatus({ open: false }));
  };

  const hideImportToQDeckModal = () => {
    dispatch(setImportToQDeckModalStatus({ open: false }));
  };

  const getUserDisplayName = (user) => {
    if (user?.firstname && user?.lastname) {
      return `${user.firstname} ${user.lastname}`;
    }
    if (user?.firstname) {
      return user.firstname;
    }
    return user.email;
  };

  const handleImportMessageToQDeck = async (message) => {
    if (!['user', 'manual'].includes(message.textType)) {
      throw new Error('Only user or agent messages can be imported to QDeck!');
    }

    const authorEmail = message.textType === 'user' ? user.memberInfo.email : store.getState().auth.user.email;
    const authorName =
      message.textType === 'user'
        ? getUserDisplayName(user.memberInfo)
        : getUserDisplayName(store.getState().auth.user);
    const authorType = message.textType === 'user' ? 'user' : 'bookkeeper';

    dispatch(
      importMessageToQDeck({
        userId: user.memberInfo.id,
        authorEmail,
        authorName,
        authorType,
        text: message.body,
        timestamp: message.time
      })
    );
  };

  const { messageList, loader } = chat;
  const { memberInfo } = user;

  return (
    <div className='chatSection'>
      <Modal open={chat.hasDataMistakesModal} size='big' handleClose={hideDataMistakesModal}>
        <DataMistakesSection />
      </Modal>
      <Modal open={chat.importToQDeckModalOpen} size='medium' handleClose={hideImportToQDeckModal}>
        {chat.importToQDeckModalMessage}
      </Modal>
      {showImageViewer && <ImageViewer imageUrl={largeMedialUrl} onClose={() => closeImageViewer()} />}
      <div className='messageList'>
        {loader && (
          <div className='chatLoader'>
            <Loader name='bars' />
          </div>
        )}
        {messageList.map((data, index) => (
          <div
            className={classNames('messageItem', {
              unhandledMessage: messageIsUnhandled(data)
            })}
            key={`msg${index}`}>
            <div className={`message ${data.className}`}>
              {data.media === '' && <Lines body={data.body} />}
              {_.map(data?.attachments, (attachment, i) => (
                <ChatAttachment attachment={attachment} userId={memberInfo.id} key={i} />
              ))}
              {data.media !== '' && (
                <div>
                  <Lines className='imageText' body={data.body} />
                  <div className='sms-media'>
                    <img src={data.media} alt='sms' onClick={() => openImageViewer(data.media)} />
                  </div>
                </div>
              )}
              <details className='time'>
                <summary>
                  {(data.textType === 'user' || data.textType === 'manual') && (
                    <OverlayTrigger
                      placement='top'
                      overlay={<Tooltip className='import-to-qdeck-tooltip'>Import Message into QDeck</Tooltip>}>
                      {({ ref, ...triggerHandler }) => (
                        <button
                          className='import-to-qdeck-button'
                          type='button'
                          ref={ref}
                          {...triggerHandler}
                          onClick={() => {
                            handleImportMessageToQDeck(data);
                          }}>
                          📩
                        </button>
                      )}
                    </OverlayTrigger>
                  )}
                  <MessageMetadata data={data} />
                </summary>
                <table>
                  <tbody>
                    {data.textType && (
                      <tr>
                        <th>Text Type</th> <td>{data.textType}</td>
                      </tr>
                    )}
                    {data.client_type && (
                      <tr>
                        <th>Client Type</th>
                        <td>
                          {(() => {
                            switch (data.client_type) {
                              case 'web':
                                return 'web';
                              case 'react native':
                                return 'app';
                              default:
                                return null;
                            }
                          })()}
                        </td>
                      </tr>
                    )}
                    {data.origin && (
                      <tr>
                        <th>Origin</th>
                        <td>{data.origin}</td>
                      </tr>
                    )}
                    {data.location && (
                      <tr>
                        <th>Location</th>
                        <td>{data.location}</td>
                      </tr>
                    )}
                    {data.page_summary && (
                      <tr>
                        <th>Page Summary</th>
                        <td style={{ whiteSpace: 'pre-line' }}>{data.page_summary}</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </details>
              {data.surveyId && (
                <div className='csatResponse'>
                  <button
                    className={classNames('happy', { pressed: data.surveyResponse === '1' })}
                    onClick={createHandleSurveyResponseClick({
                      message: data,
                      response: 1
                    })}>
                    Awesome!{' '}
                    <span role='img' aria-label='happy emoji'>
                      😊
                    </span>
                  </button>
                  <button
                    className={classNames('needsImprovement', { pressed: data.surveyResponse === '2' })}
                    onClick={createHandleSurveyResponseClick({
                      message: data,
                      response: 2
                    })}>
                    Needs improvement{' '}
                    <span role='img' aria-label='wrench emoji'>
                      🔧
                    </span>
                  </button>
                </div>
              )}
              {canReportDataMistake(data) && (
                <button className='reportDataMistake' onClick={() => showDataMistakesModal(data)}>
                  🐛
                </button>
              )}
            </div>
          </div>
        ))}
        <div
          style={{ float: 'left', clear: 'both' }}
          ref={(el) => {
            setMessagesEnd(el);
          }}
        />
      </div>

      {!isExternalAccountant && <ChatSectionFooter />}
    </div>
  );
};

export default ChatSection;
