import React, { useMemo, useState } from 'react';
import Swal from 'sweetalert2';
import MacroSection from '../../MacrosSection/MacrosSection.jsx';
import { setChatScroll } from '../../../../../actions/chatActions.js';
import { useDispatch, useSelector } from 'react-redux';
import { setSocketUsers } from '../../../../../actions/socketActions.js';
import { internalMessage, sendMessage } from '../../../../../services/messageService.js';
import { setMacroModalStatus } from '../../../../../actions/macroActions.js';
import sendIcon from '../../../../../assets/img/send.svg';
import dismissIcon from '../../../../../assets/img/dismiss.svg';
import flashIcon from '../../../../../assets/img/flash.svg';
import addIcon from '../../../../../assets/img/add.svg';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import './ChatSectionFooter.scss';
import useChatFiles from '../../../../../hooks/useChatFiles';
import ChatSectionFilePreview from './ChatSectionFilePreview.jsx';
import { getAttachmentType } from '../../../../../global/Helpers.js';
import { userSelector, socketSelector } from '../../../../../selectors/mainSelectors.js';
import store from '../../../../../store/store';
import _ from 'lodash';

const ChatSectionFooter = () => {
  const dispatch = useDispatch();
  const [message, setMessage] = useState('');

  const user = useSelector(userSelector);
  const socket = useSelector(socketSelector);

  const { stagedFile, setStagedFile, openFileSelector, onClose, clear, plainFiles, filesContent } = useChatFiles();
  const { memberInfo } = user;

  const setMacroMessage = (macroMessage) => {
    setMessage(message + ' ' + macroMessage);
  };

  const internalOnly = useMemo(() => message.length === 0, [message]);

  const handleSendChatMessage = async (e, phone, twilio_number) => {
    e.preventDefault();
    const memberPhone = memberInfo.phone;
    if (String(memberPhone) !== String(phone)) {
      Swal.fire({
        type: 'error',
        title: 'Oops...',
        text: `Please reselect member.`
      });
      return;
    }

    const { users } = socket;
    const { user: bookkeeper } = store.getState().auth;
    const filteredUsers = users.filter((u) => u !== phone);

    const file = (() => {
      const fileType = plainFiles[0]?.type;
      const fileContent = _.get(filesContent, '0.content', '');
      if (!fileType || !fileContent) {
        return null;
      }
      return { type: fileType, data: fileContent?.split(',')[1], imageUrl: fileContent };
    })();

    const SMSobj = {
      id: memberInfo.id,
      phone,
      text: message,
      bookkeeper_id: bookkeeper.id,
      keeper_number: internalOnly ? null : twilio_number,
      platform: memberInfo.platform,
      keeper_id: memberInfo.keeper_id,
      file
    };
    try {
      dispatch(setSocketUsers(filteredUsers));
      if (internalOnly) {
        SMSobj.text = `[Internal only] ${SMSobj.message || 'User good to go'}`;
        dispatch(internalMessage(SMSobj));
      } else {
        dispatch(sendMessage(SMSobj));
      }
      dispatch(setChatScroll({ scroll: true, style: 'smooth' }));
    } catch (e) {
      console.log(e);
      Swal.fire({ type: 'error', title: 'Oops...', text: e });
    } finally {
      setStagedFile(null);
      clear();
      setMessage('');
    }
  };

  return (
    <>
      <MacroSection setMacroMessage={setMacroMessage} />
      {stagedFile && (
        <ChatSectionFilePreview file={stagedFile} type={getAttachmentType(stagedFile?.type)} onClose={onClose} />
      )}
      <div className='chat-section-footer'>
        <IconButton onClick={openFileSelector}>
          <img alt='send' src={addIcon} style={{ filter: 'brightness(0)' }} />
        </IconButton>
        <TextField
          fullWidth
          variant='outlined'
          multiline
          onChange={(e) => setMessage(e.target.value)}
          placeholder='Reply to user'
          value={message}
          autoComplete='off'
          InputProps={{
            sx: { borderRadius: '12px' },
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton
                  onClick={(e) => {
                    handleSendChatMessage(e, memberInfo.phone, false, memberInfo.twilio_number);
                  }}>
                  <img alt='send' src={internalOnly ? dismissIcon : sendIcon} style={{ filter: 'brightness(0)' }} />
                </IconButton>
                <IconButton
                  onClick={() => {
                    dispatch(setMacroModalStatus(true));
                  }}>
                  <img alt='macros' src={flashIcon} style={{ filter: 'brightness(0)' }} />
                </IconButton>
              </InputAdornment>
            )
          }}
        />
      </div>
    </>
  );
};

export default ChatSectionFooter;
