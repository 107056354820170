import React from 'react';
import { CloseCircle } from 'iconsax-react';
import './ChatSectionFilePreview.scss';

const ChatSectionFilePreview = ({ file, type, onClose }) => {
  const url = URL.createObjectURL(file);

  if (!type) return null;

  return (
    <div className='chat-section-file-preview-container'>
      <div className='chat-section-file-preview'>
        {(() => {
          if (type === 'file/pdf') {
            return <embed src={url} type='application/pdf' frameBorder='0' height='65px' width='50px'></embed>;
          }
          if (type === 'file/image') {
            return <img src={url} alt={file.name} className='chat-section-file-preview-image' />;
          }
          return null;
        })()}
      </div>
      <CloseCircle
        size={24}
        className='chat-section-file-preview-close'
        variant='Bold'
        color='black'
        onClick={onClose}
      />
    </div>
  );
};

export default ChatSectionFilePreview;
