import { useState, useEffect } from 'react';
import { useFilePicker } from 'use-file-picker';
import { trackActivity } from '../utils/analytics/analyticsService';

const useChatFiles = () => {
  const [stagedFile, setStagedFile] = useState(null);

  const [openFileSelector, { plainFiles, filesContent, clear }] = useFilePicker({
    accept: ['.pdf', '.jpg', '.jpeg', '.png', '.tif', '.tiff'],
    multiple: false,
    readAs: 'DataURL',
    maxFileSize: 10
  });

  const onClose = () => {
    clear();
    setStagedFile(null);
  };

  useEffect(() => {
    if (plainFiles.length > 0) {
      trackActivity('messaging: file upload staged', { filename: plainFiles[0].name, fileType: plainFiles[0].type });

      const file = plainFiles[0];
      setStagedFile(file);
    }
  }, [plainFiles, filesContent]);

  return { stagedFile, setStagedFile, openFileSelector, onClose, clear, plainFiles, filesContent };
};

export default useChatFiles;
